import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { FC, useCallback, useEffect, useState } from "react";
import { CustomResponseEditorStyles } from "./ResponseEditor/CustomSurveyJsStyles"
import "./ResponseEditor/registerENPSQuestion"
import "survey-core/defaultV2.min.css";
import styled from "styled-components";
import { observer } from "mobx-react";
import store from './store'
import { Snackbar, Alert, Backdrop, CircularProgress } from "@mui/material";
import { SnackbarOrigin } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

type SurveyResponseFormProps = {
  jsonForm: any;
  answers: string;
};

const SurveyResponseForm: FC<SurveyResponseFormProps> = observer((props) => {

  const [survey, _] = useState(new Model(props.jsonForm));


  const surveyComplete = () => {
  };

  const surveyChanged = useCallback((sender) => {
    store.SaveAnswersTemporarily(JSON.stringify(sender.data))
  }, []);


  const surveyCompleting = (_, options) => {
    options.allow = store.success;
    options.allowComplete = store.success;
  }

  const serverValidateQuestions = async (sender, options) => {
    if (store.validated) {
      options.complete();
      return;
    }
    store.validated = true;

    store.loading = true;
    try {
      const response = await store.AnswerSubmitted(JSON.stringify(sender.data));
      if ((response.status === 201 || response.status === 200 || response.status === 204) && response?.data !== undefined) {
        store.success = true;
      } else {
        throw new Error();
      }
    } catch (err) {
      store.openAlert = true;
      store.validated = false;
    } finally {
      store.loading = false;
    }

    options.complete();
  };


  useEffect(() => {
    store.setSurveyIsoLanguages(survey.getUsedLocales())
  }, [props.jsonForm])

  useEffect(() => {
    if (props.answers !== null && props.answers !== "") {
      survey.data = JSON.parse(props.answers)
    }
  }, [props.answers])


  useEffect(() => {
    if (store.Attributes?.length) {
      store.Attributes.forEach(attribute => {
        survey.setVariable(attribute.name, attribute.value)
      })
    }
  }, [store.Attributes])

  useEffect(() => {
    survey.locale = store.selected_language
  }, [store.selected_language])

  survey.onServerValidateQuestions.add(serverValidateQuestions)
  survey.onValueChanged.add(surveyChanged);
  survey.onComplete.add(surveyComplete);
  survey.onCompleting.add(surveyCompleting);

  return <>
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" } as SnackbarOrigin}
      open={store.openAlert}
      onClose={() => store.openAlert = false}
      autoHideDuration={3000}
    >
      <StyledAlert
        icon={<CancelIconWrapp />}
        onClose={() => store.openAlert = false}
        $severity={"error"}
      >
        Something went wrong, please try again
      </StyledAlert>
    </Snackbar>


    <Backdrop sx={{ color: "#fff", zIndex: 1000000 }} open={store.loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
    <CustomResponseEditorStyles>
      <Survey model={survey} />;
    </CustomResponseEditorStyles>
  </>

});

const StyledAlert = styled(Alert) <{ $severity?: "success" | "info" | "warning" | "error" }>`
  background-color: var(--colorNeutralForeground1, #3e4450) !important;
  color: var(--colorNeutralBackground1, #fff) !important;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

const CancelIconWrapp = styled(CancelIcon)`
  color: var(--colorPaletteRedForeground2, #bd0202) !important;
`;

export default SurveyResponseForm;
